angular.module('app').controller('PaymentInstructionsPageController',['$scope','tools','ctep','Idle',
                                            function($scope,tools,ctep,Idle) {
  var model=$scope.model;
  if(model.ctepVisit!==null && model.ctepVisitAmount!==0.0 && ctep.claimedCtepterminal!==null) {
    ctep.startPayment(model.ctepVisit,model.ctepVisitAmount,model).then(function(response) {
      if(response) {
        tools.redirect('kioskFinished');
      } else {
        Idle.watch();
        $scope.redirectToActivities();
      }
    });
  }

  Idle.unwatch();

}]);
