angular.module('app').controller('FinishedPageController',['$scope','$timeout','$document','modelstore','tools','webservice',
                                            function($scope,$timeout,$document,modelstore,tools,webservice) {
  var parentModel=$scope.model;
  $scope.parentModel=parentModel;
  var model={};
  $scope.model=model;
  var script='public/reservationC';

  $scope.getTicketnr = function() {
    var result;
    if(model.ticket===null) {
      result='unavailable';
    } else {
      var ticketnr=model.ticket.ticketnr.toString();
      result=ticketnr.substr(0,3)+'.'+ticketnr.substr(3);
    }
    return result;
  };

  $scope.printPdf = function(pdf) {
    var binary=atob(pdf);
    var len=binary.length;
    var buffer=new ArrayBuffer(len);
    var view=new Uint8Array(buffer);
    for(var i=0;i<len;i++) {
      view[i]=binary.charCodeAt(i);
    }
    var blob=new Blob([view],{'type':'application/pdf'});
    var iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = URL.createObjectURL(blob);
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  };

  $scope.resetKayakInstructions = function() {
    model.showKayakInstructions={'KAYDUR':false,'KAYBNO':false,'KAYCHO':false};
  };

  tools.initAttributes(model,{
    'ticket':null
  });
  $scope.resetKayakInstructions();
  if(model.ctepVisit!==null) {
    modelstore.cleanModel(['activities','tickets'],model);
    $scope.resetKayakInstructions();
    webservice.call('getTicket',{'visitId':parentModel.ctepVisit.id},script,model).then(function(response) {
      if(response!==false) {
        model.ticket=tools.first(model.tickets);
        model.showKayakInstructions=Object.values(model.activities).reduce(function(showKayakInstructions,activity) {
          if(activity.frm in showKayakInstructions) {
            showKayakInstructions[activity.frm]=true;
          }
          return showKayakInstructions;
        },model.showKayakInstructions);
        $scope.printPdf(response.ticket);
        if('receipt' in response) {
          $timeout(function() {
            $scope.printPdf(response.receipt);
          },2000);
        }
        $timeout(function() {
          $scope.resetKiosk();
        },15000);
      }
    });
  }

}]);
