'use strict';
var config = function($window,$log) {
  this.$window=$window;
  this.$log=$log;
  this.hosts={
    'localhost': {
      'serviceUrl':'http://localhost/outdoor/src/services/index.php',
      // 'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit',
      'ctepHttpProtocol':'https',
      'imageService':'http://localhost/outdoor/src/services/templateimage.php',
      // 'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'ctepWsProtocol':'wss',
      'devIsAvailable':true,
      'ctepScript':'ctepForKiosk',
      'mode':'site',
      'ffo':'AV',
      'formbutton':'',
      'url':'http://localhost:3002/',
      'urlhash':'e73b62e92e45f1bdae2c1ca7b543befc4b3bad0fa54f64ca2e54df752359b2a1',
      'promoCode':'',
      'termsAndConditionsLinks':[],
      'privacyLink':{
        label:{en:'privacy',nl:'privacy',fr:'vie priv\u00E9e'},
        href:{
          en:'http://www.big-c.be/',
          nl:'http://www.big-c.be/',
          fr:'http://www.big-c.be/'
        }
      },
      'finishedRedirect': null,
      'proposeVoucherPayment':false,
      'fixedDateIntervals':null
    },
    'kiosk': {
      'serviceUrl':'http://localhost/outdoor/src/services/index.php',
      'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'ctepHttpProtocol':'https',
      'ctepWsProtocol':'wss',
      'devIsAvailable':false,
      'ctepScript':'ctepForKiosk'
    },
    'dev': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'www': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'outdoorpub': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit'
    },
    'outdoor': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'ctepHttpProtocol':'https',
      'ctepWsProtocol':'wss',
      'devIsAvailable':false,
      'ctepScript':'ctepForKiosk'
    },
    'adventure-valley': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'durbuyfestival': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'takeawaybybru': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'reservation': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'lunch': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'breakfast': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'booking': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'booking-halloween': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'tml': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'stages': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'cera': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit',
      'undefinedRefererRedirectUrl': {
        'en' : 'https://www.cera.coop/fr/particuliers/profitez-des-avantages/chercher-un-avantage/arrangements-durbuy_b809c1d4656d4548b1b7e39e0f96bdbf',
        'fr' : 'https://www.cera.coop/fr/particuliers/profitez-des-avantages/chercher-un-avantage/arrangements-durbuy_b809c1d4656d4548b1b7e39e0f96bdbf',
        'nl' : 'https://www.cera.coop/nl/particulieren/genieten-van-voordelen/voordelen-zoeken/verwennerij-in-durbuy_b809c1d4656d4548b1b7e39e0f96bdbf'
      }
    },
    'mr': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit',
      'undefinedRefererRedirectUrl': {
        'en' : 'https://www.mr.be/agenda/estivales-a-durbuy-adventure/',
        'fr' : 'https://www.mr.be/agenda/estivales-a-durbuy-adventure/',
        'nl' : 'https://www.mr.be/agenda/estivales-a-durbuy-adventure/'
      }
    },
    'glamping': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'neckermann': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'ardennes-etape': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit',
      'undefinedRefererRedirectUrl': {
        'en':'https://en.ardennes-etape.be/',
        'nl':'https://www.ardennes-etape.be/',
        'fr':'https://fr.ardennes-etape.be/'
      }
    },
    'dinant': {
      'serviceUrl':'http://dinant.webdigitales.eu/services/index.php',
      'imageService':'http://dinant.webdigitales.eu/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit'
    },
    'bigcpub': {
      'serviceUrl':'https://outdoorapp.be/services/index.php',
      'imageService':'https://outdoorapp.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit',
      'mode':'site',
      'ffo':'BC',
      'formbutton':'',
      'url':'https://bigcpub.outdoorapp.be/',
      'urlhash':'38dfb94d27f7009a5ae4f33e42404017d2ccc45a200335160bf640562fd2819f',
      'promoCode':'',
      'termsAndConditionsLinks':[],
      'privacyLink':{
        label:{en:'privacy',nl:'privacy',fr:'vie priv\u00E9e'},
        href:{
          en:'http://www.big-c.be/',
          nl:'http://www.big-c.be/',
          fr:'http://www.big-c.be/'
        }
      },
      'finishedRedirect': null,
      'proposeVoucherPayment':false,
      'fixedDateIntervals':null
    }
  };
  this.host=null;
  this.langs=['fr','nl','en'];
};
config.prototype.get = function(key) {
  var host=this.getHost();
  return host[key];
};
config.prototype.set = function(key,value) {
  var host=this.getHost();
  host[key]=value;
};
config.prototype.exists = function(key) {
  var host=this.getHost();
  return host.hasOwnProperty(key);
};
config.prototype.getHost = function() {
  if(this.host===null) {
    var hostname=this.$window.location.hostname;
    if(hostname.match(/^www\./)) {
      hostname=hostname.substr(4);
    }
    hostname=hostname.split('.')[0];
    if(hostname in this.hosts) {
      this.host=this.hosts[hostname];
      var i;
      for(i in this.$window.outdoorArgs) {
        this.host[i]=this.$window.outdoorArgs[i];
      }
    } else {
      this.$log.log('ERROR: domain <'+hostname+'> is not configured');
    }
  }
  return this.host;
};
angular.module('app').service('config',['$window','$log',config]);
