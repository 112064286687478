angular.module('app').controller('UndefinedRefererModalController',['$scope','parameters', function($scope,parameters) {
    $scope.inputValueLength='';
    $scope.checkExternalid = function() {
      $scope.error='';
      $scope.inputValueLength=$scope.externalid.length;
      var regex=new RegExp(parameters.FFO[$scope.ffo].field11);
      if(regex.test($scope.externalid)) {
        $scope.$close({'externalid':$scope.externalid});
      } else {
        $scope.formError=true;
      }
    };
}]);
